



































import Vue from 'vue';
import RegionSelect from "@/components/region/RegionSelect.vue";
import LoadingErrorable from '@/models/util/LoadingErrorable';
import IRegion from '@/models/region/IRegion';
import RegionProvider, { localRegion } from '@/api/RegionProvider';
import Loader from "@/components/util/Loader.vue";
import { Actions } from '@/storemutations';
// @ts-ignore
import { Portal } from '@linusborg/vue-simple-portal';

interface IData {
    showSelect: boolean;
    regions: LoadingErrorable<IRegion[], any>;
    regionList: IRegion[];
    disable: boolean;
    reloading: boolean;
}

export default Vue.extend({
    components: {
        RegionSelect,
        Loader,
        Portal,
    },
    data(): IData {
        return {
            showSelect: false,
            regions: new LoadingErrorable(),
            regionList: [],
            disable: false,
            reloading: false,
        };
    },
    computed: {
        selectedRegionCode(): string {
            return this.$store.state.regionCode;
        },
        selectedRegion(): IRegion|null {
            const ret = this.regionList.find(r => r.shortName == this.selectedRegionCode);
            if (!ret) {
                return null;
            }

            return ret;
        },
        renderedName(): string {
            if (this.selectedRegion) {
                const localizedName = this.selectedRegion.displayNames[this.selectedRegion.defaultLocale];
                const defaultName = this.selectedRegion.displayNames.default;
                if (localizedName && localizedName !== defaultName) {
                    return `${localizedName} (${defaultName})`;
                }

                return defaultName;
            }

            return 'Unknown region';
        },
        renderedVersion(): string {
            if (this.selectedRegion && this.selectedRegion.id > 0) {
                return `v${this.selectedRegion.version || '???'}`;
            }

            return '';
        },
        canChangeServer(): boolean {
            return this.regionList.length > 1 || this.selectedRegionCode === localRegion;
        }
    },
    mounted() {
        this.loadRegions();
    },
    methods: {
        async showSelector() {
            if (!this.canChangeServer) {
                return;
            }

            this.showSelect = true;
            await Vue.nextTick();
            this.$anime({
                targets: ".region-selector",
                opacity: {
                    value: [0.0, 1.0],
                    elasticity: 0,
                },
                duration: 2000,
            });
            this.$anime({
                targets: ".region-selector .container",
                translateY: [-200, "-50%"],
                translateX: ["-50%", "-50%"],
                elasticity: 5,
                duration: 1000,
            });
        },
        loadRegions() {
            this.regions.startLoad();
            RegionProvider.listRegions().then((regions) => {
                this.regions.done(regions);
                this.regionList = regions;
            });
        },
        selectRegion(region: IRegion) {
            this.disable = true;
            this.reloading = true;
            this.$store.dispatch(Actions.SetRegion, region.shortName);
            const ani = this.$anime({
                targets: ".region-selector .container",
                opacity: {
                    value: [1.0, 0.0],
                    elasticity: 0,
                },
                translateY: [0, -10],
                translateX: ["-50%", "-50%"],
                duration: 1000,
            });
            ani.finished.then(() => {
                window.location.reload();
            });
        },
        cancel() {
            this.disable = true;
            const ani = this.$anime({
                targets: ".region-selector",
                opacity: {
                    value: [1.0, 0.0],
                    elasticity: 0,
                },
                translateY: [0, 10],
                duration: 1000,
            });
            ani.finished.then(() => {
                this.showSelect = false;
                this.disable = false;
            });
        }
    }
});
